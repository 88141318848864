.commonHead {
  padding: 0 0.3rem;
  background-image: url(../assets/imgs/common_bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 3.5rem;
}
.commonHead .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
}
.commonHead .left {
  position: absolute;
  left: 0;
  top: 0;
}
.commonHead .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHead .left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHead .title {
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  text-transform: capitalize;
}
.commonHead .right {
  position: absolute;
  right: 0;
  top: 0;
  height: 0.88rem;
  display: flex;
  align-items: center;
}
.commonHead .right .text {
  color: #FFFFFF;
  font-size: 0.24rem;
}
.commonHead .right img {
  width: 0.4rem;
  height: 0.4rem;
}
