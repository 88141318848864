

























































.commonHead {
	padding: 0 .3rem;
	background-image: url(../assets/imgs/common_bg.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 3.5rem;
	.head {
		position: relative;
		height: .88rem;
		line-height: .88rem;
	}
	.left {
		position: absolute;
		left: 0;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.title {
		color: #ffffff;
		font-size: .32rem;
		// font-weight: bold;
		text-align: center;
		text-transform: capitalize;
	}
	.right {
		position: absolute;
		right: 0;
		top: 0;
		height: .88rem;
		display: flex;
		align-items: center;
		.text {
			color: #FFFFFF;
			font-size: .24rem;
		}
		img {
			width: .4rem;
			height: .4rem;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;